<template>
    <div style="background-color: #f2f2f2; margin-top: 8px">
        <el-card style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" size="small" label-width="85px">
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select v-model="form.deptGroupCode" @change="handleSelectDeptGroup" filterable>
                                <el-option
                                    v-for="item in deptGroups"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable>
                                <el-option
                                    v-for="item in deptList"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="24">
                        <el-form-item label="推广会员日期" label-width="100px">
                            <el-date-picker
                                v-model="form.dates"
                                type="daterange"
                                :unlink-panels="true"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.report.distributionMemberDeptCount.open')"
            >
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.distributionMemberDeptCount.export')"
            >
                导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card style="margin-top: 8px">
            <el-table
                id="table"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="460"
            >
                <el-table-column label="序号" type="index" width="80" align="center" fixed="left" />
                <el-table-column prop="deptName" label="机构名称" width="230" v-if="showColumn('deptName')" />
                <el-table-column
                    prop="deptGroupName"
                    label="机构组名称"
                    width="240"
                    v-if="showColumn('deptGroupName')"
                />
                <el-table-column
                    prop="promotionMemberCount"
                    label="推广会员数量"
                    width="150"
                    v-if="showColumn('promotionMemberCount')"
                />
                <el-table-column prop="deptDeductionAmount" width="150" v-if="showColumn('deptDeductionAmount')">
                    <template slot="header">
                        <el-tooltip
                            effect="light"
                            content="本机构员工推广产生的订单收款到八号地，且已抵扣给进货门店的累计商分金额"
                            placement="top"
                        >
                            <span>门店进货抵扣商分金额 <i class="el-icon-info"></i></span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="deptGroupDeductionAmount"
                    width="150"
                    v-if="showColumn('deptGroupDeductionAmount')"
                >
                    <template slot="header">
                        <el-tooltip
                            effect="light"
                            content="本机构员工推广产生的订单收款到八号地，且已抵扣给进货市场的累计商分金额。"
                            placement="top"
                        >
                            <span>市场进货抵扣商分金额 <i class="el-icon-info"></i></span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="orderDimensionAmount" width="150" v-if="showColumn('orderDimensionAmount')">
                    <template slot="header">
                        <el-tooltip effect="light" content="市场收款订单中，累计结算到本机构的商分金额" placement="top">
                            <span>分账订单商分金额 <i class="el-icon-info"></i></span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="clearAmount" width="150" v-if="showColumn('clearAmount')">
                    <template slot="header">
                        <el-tooltip effect="light" content="市场与门店清算商分金额之和" placement="top">
                            <span>已清算商分金额 <i class="el-icon-info"></i></span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="noClearAmount" width="150" v-if="showColumn('noClearAmount')">
                    <template slot="header">
                        <el-tooltip
                            effect="light"
                            content="市场未与门店结算商分金额之和。未清算商分金额=分账商分金额 +市场进货抵扣商分金额 – 已清算商分金额"
                            placement="top"
                        >
                            <span>未清算商分金额 <i class="el-icon-info"></i></span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="
                        hasPrivilege('biz.report.distributionMemberDeptCount.clear') ||
                        hasPrivilege('biz.report.distributionMemberDeptCount.clear.log')
                    "
                    label="操作"
                    min-width="200"
                    header-align="center"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            v-if="hasPrivilege('biz.report.distributionMemberDeptCount.clear')"
                            size="mini"
                            type="primary"
                            @click="clearAmount(scope.row)"
                            >清算商分</el-button
                        >
                        <el-button
                            v-if="hasPrivilege('biz.report.distributionMemberDeptCount.clear.log')"
                            size="mini"
                            @click="lookRecord(scope.row)"
                            >操作记录</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                :total="total"
                :default-limit="form.limit"
                @pageChange="pageChange"
                :sizes-style-flag="true"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <!-- 清算商分的弹窗 -->
        <el-dialog title="清算商分" :visible.sync="showClearScoreDialog" width="30%" top="10%">
            <div>
                <el-form>
                    <el-form-item label="清算商分金额（元）">
                        <el-input-number
                            :min="0"
                            :max="clearForm.noDeductionAmount"
                            :precision="2"
                            style="width: 78%"
                            placeholder="抵扣商分金额"
                            v-model="clearForm.changeAmount"
                        ></el-input-number>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showClearScoreDialog = false">取 消</el-button>
                <el-button type="primary" @click="changeDeductionAmount">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 操作记录的弹窗 -->
        <el-dialog title="操作记录" :visible.sync="distributionRecordDialog" width="60%" top="8%" border>
            <div>
                <el-table :data="recordList" height="500px" style="margin-bottom: 20px">
                    <el-table-column align="center" label="清算商分金额" prop="changeAmount"></el-table-column>
                    <el-table-column align="center" label="操作人" prop="creator"></el-table-column>
                    <el-table-column align="center" label="操作时间" prop="createTime"></el-table-column>
                </el-table>
                <div class="block">
                    <el-pagination
                        @size-change="handleSizeRecordChange"
                        @current-change="handleCurrentRecordChange"
                        :current-page="recordPage.page"
                        :page-sizes="[10, 20, 50, 100, 200]"
                        :page-size="recordPage.limit"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="recordPage.total"
                    >
                    </el-pagination>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import EfPagination from 'components/EfPagination';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'DistributionMemberDeptCount',
    components: { EfPagination, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        //初始化时间
        function getMonthStartDayStr() {
            // 获取本月第一天
            const now = new Date();
            return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + '01';
        }

        function getNowStr() {
            //获取当天
            const now = new Date();
            return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
        }

        return {
            form: {
                deptGroupCode: null,
                deptCode: null,
                dates: [getMonthStartDayStr(), getNowStr()],
                startTime: null,
                endTime: null,
                page: 1,
                limit: 10,
            },
            url: {
                queryDeptByGroup: '/system/dept/deptTree',
            },
            deptGroups: [],
            deptList: [],
            total: 0,
            tableData: [],
            showClearScoreDialog: false,
            clearForm: {
                noDeductionAmount: 0,
                changeAmount: 0,
                rowData: {},
            },
            recordPage: {
                total: 0, // 总页数
                page: 1, // 当前页数
                limit: 10, // 每页显示多少条
            },
            recordJson: {}, //操作记录当前的数据信息
            distributionRecordDialog: false,
            recordList: [],
        };
    },

    mounted() {
        //查询机构组
        UrlUtils.DeptGroup(this, (rst) => {
            this.deptGroups = rst;
            if (this.deptGroups && this.deptGroups.length > 0) {
                // 默认第一个
                this.form.deptGroupCode = this.deptGroups[0].code;
                //触发机构组查询机构
                this.handleSelectDeptGroup(this.form.deptGroupCode, this.handleQuery);
            }
        });
    },
    methods: {
        handleSizeRecordChange(val) {
            this.recordPage.limit = val;
            this.lookRecord(this.recordJson, this.recordPage);
        },
        handleCurrentRecordChange(val) {
            this.recordPage.page = val;
            this.lookRecord(this.recordJson, this.recordPage);
        },
        // 操作记录
        lookRecord(item, page) {
            this.distributionRecordDialog = true;
            if (item) {
                this.recordJson = item;
            }
            if (!page) {
                page = { total: 0, page: 1, limit: 10 };
            }
            this.$efApi.distributionMemberDeptCountApi
                .queryDistributionRecordPage(this.recordJson.deptCode, page)
                .then((data) => {
                    this.recordList = data.data || [];
                    this.recordPage.total = data.count;
                });
        },
        clearAmount(row) {
            this.showClearScoreDialog = true;
            this.clearForm.noDeductionAmount = row.noClearAmount;
            this.clearForm.rowData = row;
            this.clearForm.changeAmount = null;
        },
        changeDeductionAmount() {
            if ((this.clearForm.changeAmount || 0) > 0) {
                const h = this.$createElement;
                this.$msgbox({
                    title: '确认清算商分金额',
                    message: h('p', null, [
                        h('p', null, `机构名称：${this.clearForm.rowData.deptName} `),
                        h('p', null, `清算商分金额：${this.clearForm.changeAmount}元 `),
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then((action) => {
                    this.$efApi.distributionMemberDeptCountApi
                        .changeDeptDistributionAmount(this.clearForm.rowData.deptCode, this.clearForm.changeAmount)
                        .then(() => {
                            this.showClearScoreDialog = false;
                            this.handleQuery();
                        });
                });
            } else {
                this.$message.warning('抵扣商分金额必须大于0');
            }
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        async handleQuery() {
            if (this.form.dates.length > 0) {
                this.form.startTime = this.form.dates[0];
                this.form.endTime = this.form.dates[1];
            }
            const rst = await this.$efApi.distributionMemberDeptCountApi.distributionMemberDeptCountList(this.form);
            this.tableData = rst.data;
            this.total = rst.count;
        },
        handleSelectDeptGroup(groupCode, fun) {
            this.deptList = [];
            this.form.deptCode = '';
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + groupCode, (rst) => {
                this.deptList = rst;
                //在第一条添加以选择
                if (!this.deptList) {
                    // 不存在 新数组 保证显示一致性
                    this.deptList = [];
                }
                //添加第一条请选择
                this.deptList.unshift({ name: '请选择', code: '' });
                //设置默认请选择
                this.form.deptCode = '';
                //存在函数在回调后指定函数
                if (fun) {
                    fun();
                }
            });
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        handleExport() {
            UrlUtils.Export(
                this,
                '员工推广会员数据汇总表',
                `${this.$efApi.distributionMemberDeptCountApi.baseUrl}/exportExcelPromotionMemberDepartmentCountQueryForSystemC`,
                this.form
            );
        },
        handlePrint() {
            this.printHTML('table', this.$route.meta.title);
        },
    },
};
</script>
